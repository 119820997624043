/*
 *  Responsive Image for API-MEDIA
 *  @EstudiOliver 2018
 */
// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
import * as $$ from 'jquery'
;( function( $, window, document, undefined ) {

	"use strict";

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variable rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "responsiveImage",
			defaults = {
				breakpoints	: [1920, 1200, 992, 768, 480],
				command		: false,
				source		: false,
                ratio       : 2,
				mode        : 'w',
				lofi        : true,
				host        : '(assets|cdn).gestioweb.pro|(assets|cdn).(appculturamataro|aplicacionsculturamataro).cat|localhost|apimediacultura.b-cdn.net|api-mediacultura-node.b-cdn.net'
			};

		// The actual plugin constructor
		function Plugin ( element, options, defer ) {
			this.element = element;

			this.settings  = $.extend( {}, defaults, options, $(this.element).data() );
			this._defaults = defaults;
			this._name     = pluginName;
			this._deferred  = defer;
			this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
			init: function() {

				var src= $(this.element).data('src') || $(this.element).attr('src');

				if (!this.validateHost(src))
				{
                    this._deferred.resolve(this);
					return false;
                }

                if(!this.settings.lofi) $(this.element).addClass('loader');

				// Show original src
				if(this.settings.source){

					var cmd = this.getCommandFromUrl(src);

                    if (this.isDataSrc())
                        $(this.element).attr('data-src',src.replace('/'+cmd,''));
                    else
                        $(this.element).attr('src',src.replace('/'+cmd,''));

                    this._deferred.resolve(this);
                    return;
				}

				// If exists the command at settings, overwrite the url or set new
				if(this.validateCommand(this.settings.command)){

					if (this.isDataSrc())
						$(this.element).attr('data-src',this.setCommandToUrl(src,this.settings.command));
					else
                        $(this.element).attr('src',this.setCommandToUrl(src,this.settings.command));

                    this._deferred.resolve(this);
					return;

				}else{

				    // If the url have c_auto inside, change it
					var cmd = this.getCommandFromUrl(src);

					if (/c_auto/.test(cmd))
					{
                        if (this.isDataSrc()){
                            //$(this.element).attr('data-src',this.setCommandToUrl(src, this.breakPoint()));
                            $(this.element).attr('data-src', src.replace("c_auto", this.breakPoint()));
                            // Setting lowfi to preload
                            if(this.settings.lofi) this.setlowFi(src);
                        }else
                            //$(this.element).attr('src',this.setCommandToUrl(src, this.breakPoint()));
                            $(this.element).attr('src', src.replace("c_auto" ,this.breakPoint()));

					}

                    this._deferred.resolve(this);
					return;
                }

			},

			breakPoint: function() {

				this.setModeMobileNoneTagImg();

				var w       = $(window).width(),
					h       = $(window).height() * this.settings.ratio,
				    preset  = this.arrayMax(this.settings.breakpoints) || 1920,
                    settings = this.settings;

                $.each(this.settings.breakpoints, function( i, breakpoint)
				{
					if (settings.mode=="h")
                    	if (breakpoint >= h) preset = breakpoint;

					if (settings.mode=="w")
						if (w <= breakpoint) preset = breakpoint;
				});

                return "p_" + preset;
			},
			setModeMobileNoneTagImg:function(){

				var $el = $(this.element);
				if( ($el[0].tagName != "IMG") && (this.isMobile('768px')) )
				{
					this.settings.mode = "h";
				}

			},
            isMobile:function(match) {
                var mq = window.matchMedia('(max-width: '+ match+')');
                return (mq.matches)? true : false;
            },
			validateHost: function(_url){
				return new RegExp(this.settings.host.replace('.','\.')).test(_url);
			},

			validateCommand:function(_command){
				return /((w|h|p|q)_[0-9]+?|(e|c|g)_[a-zA-Z_-]+?,?)/.test(_command || this.settings.command);
			},
            isDataSrc:function(){
				return typeof $(this.element).data('src') === 'string';
			},
			getCommandFromUrl: function(_url){
				try
				{
                    var part = _url.split('/').slice(-2)[0];
                    return (this.validateCommand(part)) ? part : false;
                }
                catch(e)
				{
					return false;
				}
			},

            arrayMax:function(array) {
				return array.reduce(function(a, b) {
					return Math.max(a, b);
				});
			},

			setCommandToUrl:function(_url, _cmd){
			  try {

                  var parts = _url.split('/'),
                      command = this.getCommandFromUrl(_url);

                  if (!!command) {
                      return _url.replace(command, _cmd);// replace commands
                  }
                  else {

                      // Inject the command
                      var name = [parts.pop()],
                          cmd = [_cmd],
                          url = parts.concat(cmd, name);

                      return url.join('/')
                  }
              }
              catch(e)
			  {
			  	 return _url;
			  }

			},

			setlowFi:function(src){

				$(this.element).addClass('blur');

				if ($(this.element)[0].tagName == "IMG") {
                    $(this.element).attr('src', src.replace("c_auto",  this.breakPoint()+",q_10"));
                }else{
                    $(this.element).css('background-image', 'url(' + src.replace("c_auto", this.breakPoint()+",q_10") + ')');
                }
			}

		} );

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
            var def = $.Deferred(), deferredList = [];
			this.each( function() {

                var innerDef = $.Deferred();
                deferredList.push(innerDef.promise());

				if ( !$.data( this, "plugin_" + pluginName ) ) {
					$.data( this, "plugin_" +
						pluginName, new Plugin( this, options , innerDef) );
				} else {
                    innerDef.resolve();
                }

			} );

            $.when.apply($, deferredList).done(function() {
                def.resolve();
            });

			return def.promise();
		};

} )( jQuery, window, document );
