// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// POLYFILL & EXTEND
import 'object-assign-polyfill';
//==== [ BASE COMMONS ] =====
import 'jquery'
import 'jquery-ujs'
import 'channels'
import '../vendors/cookielaw/jquery.cookieLaw'
import '../vendors/matchmedia/matchMedia'
import '../vendors/matchmedia/matchMedia.addListener'

//==== [ LOGGER ] =======
//import '../vendors/logger/jsLogger.min'

//=====[ FRAMEWORK ] ======
import '../vendors/kube-6.5.2/kube'
import App from '../application/src/app'

//==== [ PLUGINS] =====
import('../application/src/plugins') // chunk() async
import('../application/src/customs/plugins')// chunk() async

//==== [ COMMONS] =====
import '../application/src/commons'
import '../application/src/classes'
import '../application/src/controllers'

//==== [ BASE & CUSTOMS WIDGETS ] =====
import '../application/src/widgets'
import '../application/src/customs/widgets'

//====== INITS ========
global.$ = $
App.start()

//==== CSS ENTRY POINT - BASE ======
import '../application/css/application.scss'
import '../application/css/customs/index.scss'
