/**
 *  TO EXTEND APP
 * @param parentfn , get all  private functions
 */
export default function (parentfn) {
    const self = this;  // main (public)
    const setup =() => {
        // overwrite private functions from parent like parentfn._install =()=>{ ... }
    }
    //Auto execute private overwrites
    if (parentfn){ setup(); }
    // Extend and overwrite functions like start, init etc.. this.start=()=>{...} = (public  App.start())

}