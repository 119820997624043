/*
  FUNCIÓN GLOBAL QUE INICILIAZA LA APP
 */
import * as $$ from 'jquery'
import '../../vendors/responsive_image/jquery.responsiveImage'
import '../../vendors/lazyload/jquery.unveil'
import '../../vendors/responsive_carousel/responsiveCarousel'
import '../../vendors/owlCarousel2/owl.carousel.min'
import 'jquery.maskedinput/src/jquery.maskedinput'
import { toastr } from '../../vendors/toast_rails/toast-rails'
import   AppExt   from './app_extend'

export default ( (window,document) => {
    let ctx = {}; // private context
    // PUBLIC APP
    window.App     || (window.App = {});
    App.controller || ( App.controller = {});
    App.main       || (App.main = {});
    App.config     || (App.config = {});

    App.init = () => {

        App.config.ajax = {
            messages: true,
            loader: true,
            redirect_login_url: '/login',
            redirect_locked_url: '/locked'
        };
        console.debug("Debug", $(document.body).data('debug') || false);
        ctx._installEvents();
        ctx._commons();
        ctx._install();
    };

    ctx._install = () => {
        let action, controller;
        controller = $(document.body).data('controller');
        action     = $(document.body).data('action');
        App.main[controller] = App.controller[controller] ? new App.controller[controller] : undefined ;
        if (App.main[controller] && App.main[controller].__proto__.hasOwnProperty(action)) {
            App.main[controller][action]()
        } else {
            console.warn("There is no " + controller + " or " + action + " action to initialise.Check if needed, or omit this warning.");
        }
    };

    ctx._onAjaxComplete = (e,xhr) => {
        let dataset, item, msg, obj, _i, _len, _ref,_xhr;
        App.utils.loaderOFF();
        msg = xhr.getResponseHeader('X-Message');
        if (App.config.ajax.messages) {
            if (JSON.parse(msg) instanceof Array) {
                _ref = obj = JSON.parse(msg);
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                    item = _ref[_i];
                    item[1] = utf8_decode(item[1]);
                }
                setTimeout((function() {
                    return App.utils.autonotify(obj);
                }), 100);
            }
        } else {
            console.log("ajax.messages=false , suprimidos los mensajes globales ->" + msg);
        }
        dataset = e.target.dataset || xhr.dataset;
        if (dataset && dataset.remote) {
            return ctx._executeFunctionsOnAjaxComplete();
        }
    };

    ctx._onAjaxBeforeSend = (e, xhr, settings) => {
        let ref, ref1, ref2, self;
        self = this
        xhr.dataset || (xhr.dataset = {});
        if (((ref = e.target.dataset) != null ? ref.remote : void 0) != null) {
            xhr.dataset.remote = true;
        }
        if (((ref1 = e.target.dataset) != null ? ref1.controller : void 0) != null) {
            xhr.dataset.controller = e.target.dataset.controller;
        }
        console.log('ajax:beforesend =>' + JSON.stringify(xhr.dataset));
        ctx._setLoader.apply(self, [e]);
        if (((ref2 = e.target.dataset) != null ? ref2.controller : void 0) != null) {
            //App._injectionClass.apply(this, [e.target.dataset.controller]);
        }
    };

    ctx._onAjaxStart = (e, xhr) => {};

    ctx._setLoader = (e)=> {
        let loader;
        loader = $(e.target).data('loader') || $(e.target.activeElement).data('loader');
        if (loader === void 0) {
            loader = false;
        }
        if (loader === true && App.config.ajax.loader) {
            setTimeout((function() {
                return App.utils.loaderON();
            }), 10);
        }
    };

    ctx._onAjaxError = (e, xhr) => {
        var redirect_url;
        App.utils.loaderOFF();
        if (xhr.status >= 500 || (xhr.status === 422 && !xhr.responseJSON)) {
            App.utils.notify.error(App.messages.global.errorAjax);
        }
        if (xhr.status === 401) {
            setTimeout((function() {
                return window.location.href = App.config.ajax.redirect_login_url;
            }), 1000);
        }
        if (xhr.status === 302) {
            redirect_url = xhr.getResponseHeader('X-Ajax-Redirect-Url');
            if (redirect_url !== void 0) {
                return setTimeout((function() {
                    return window.location.href = redirect_url;
                }), 100);
            }
        }
    };

    ctx._onAjaxRailsErrorValidations = (e, xhr) => {
        var key, ref, ref1, value;
        App.utils.loaderOFF();
        if (xhr.status === 422 && xhr.responseJSON) {
            if ($(document.body).hasClass('kube')) {
                $(e.target).find('input.error,textarea.error,select.error').removeClass("error").closest('.form-item').find('span.error').remove();
                ref = xhr.responseJSON;
                for (key in ref) {
                    value = ref[key];
                    $(e.target).find('[name="' + key + '"],[name$="[' + key + ']"],#' + key).eq(0).addClass("error").parent().append('<span class="error">' + $.map(value, function(m) {
                        return m.charAt(0).toUpperCase() + m.slice(1);
                    }).join('<br />') + '</span>');
                }
            } else {
                $(e.target).find('.has-error').removeClass("has-error").find('span.help-block').remove();
                ref1 = xhr.responseJSON;
                for (key in ref1) {
                    value = ref1[key];
                    $(e.target).find('[name$="[' + key + ']"],#' + key).eq(0).closest('.form-group').addClass("has-error").append('<span class="help-block">' + $.map(value, function(m) {
                        return m.charAt(0).toUpperCase() + m.slice(1);
                    }).join('<br />') + '</span>');
                }
            }
        }
    };

    ctx._installEvents = () => {
        if (App.main) ctx._uninstallEvents();

        $(document).on('ajaxComplete', ctx._onAjaxComplete);
        $(document).on('ajax:beforeSend',ctx. _onAjaxBeforeSend);
        $(document).on('ajaxStart', ctx._onAjaxStart);
        $(document).on('ajaxError', ctx._onAjaxError);
        $(document).on('ajax:error', ctx._onAjaxRailsErrorValidations);
    };

    ctx._uninstallEvents = () => {

        $(document).off('ajaxComplete', ctx._onAjaxComplete);
        $(document).off('ajax:beforeSend', ctx._onAjaxBeforeSend);
        $(document).off('ajaxStart', ctx._onAjaxStart);
        $(document).off('ajaxError', ctx._onAjaxError);
        $(document).off('ajax:error', ctx._onAjaxRailsErrorValidations);
    };

    ctx._executeFunctionsOnAjaxComplete = () => {

        App.utils.maskDateAll();
        if ($.fn.bootstrapSwitch) {
            App.utils.checktoswitch();
        }
    };

    ctx._commons = () => {
        if (App.utils.isMobileAgent.iOS()) {
            $(document.body).addClass("ios-touch");
        }
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "positionClass": "toast-bottom-center",
            "onclick": null,
            "showDuration": "700",
            "hideDuration": "300",
            "timeOut": "2000",
            "extendedTimeOut": "2000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
        if ($.fn.bootstrapSwitch) {
            $.fn.bootstrapSwitch.defaults.size = 'normal';
            $.fn.bootstrapSwitch.defaults.onColor = 'success';
            $.fn.bootstrapSwitch.defaults.onText = 'SI';
            $.fn.bootstrapSwitch.defaults.offText = 'NO';
            App.utils.checktoswitch();
        }
        if ($.mask) {
            App.utils.maskDateAll();
        }
        if ($.mask) {
            App.utils.maskDateTimeAll();
        }

        /** RESPONSIVE IMG | SEO IMG * */
        $('img , [role="img"] , .article__header , .widget__landing').not('.skip').responsiveImage().done(function() {
            console.log("lazyload img");

            /** LAZY LOAD * */
            return $('.preload-size').unveil(20, function(img) {
                var node;
                node = this;
                img.onerror = function(e) {
                    if ($(node).hasClass('article__header')) {
                        $(node).fadeOut('fast');
                    }
                    return $(node).css('background-color', '#4a4a4a').removeClass('preload-size loader').trigger('error');
                };
            });
        });

        /** SUB-MENU * */
        $('ul.sub-menu').prev('a').on('click', function(e) {
            var $caret;
            if ($(e.target).hasClass('caret')) {
                $(e.target).parent('a').trigger('click');
                e.preventDefault();
                return false;
            }
            $caret = $(this).children('.caret');
            if ($caret.hasClass('down')) {
                $caret.removeClass('down').addClass('up');
            } else {
                $caret.removeClass('up').addClass('down');
            }
            $(this).next('ul').toggleClass('sub-menu--show');
        }).append('&nbsp;<span class="caret down"></span>');

        /** CARET MENU * */
        $('.main__nav').find('ul > li').find('ul.hide').prev('a').append('&nbsp;<span class="caret down"></span>');

        /** HASHED SCROLLER  * */
        $('#offcanvas-left').on('closed.offcanvas', function() {
            var arg;
            if (window.ScrollMobile != null) {
                arg = window.ScrollMobile;
                $('html').css({
                    'overflow': '',
                    'padding-right': ''
                }).removeClass('no-scroll');
                App.utils.scroll_to_section(arg[0], arg[1]);
                console.log('closed');
                window.ScrollMobile = null;
                return;
            }
        });

        /* FIX SVG */
        $('#offcanvas-left .menu svg').on('click', function(e) {
            var href;
            e.preventDefault();
            href = $(e.currentTarget).parent('a').attr("href");
            if (href != null) {
                window.location.href = href;
            }
        });
        $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
            var self, sidebar;
            self = this;
            sidebar = $(this).closest('#offcanvas-left');
            window.ScrollMobile = null;
            if (sidebar.length) {
                window.ScrollMobile = [self, event];
                return sidebar.offcanvas('closeAll').trigger('closed.offcanvas');
            } else {
                return App.utils.scroll_to_section(self, event);
            }
        });

        /** SWITCH LANGUAGE * */
        $('.lang-switch').on("change", function(e) {
            return App.utils.goto($(e.target).children(':selected').data('path'));
        });
        $('.crsl-items').carousel().on('initCarousel', function() {
            return $(document).trigger('resize.unveil');
        });

        /** FORCE LAZY ON LOAD **/
        window.addEventListener('load', (event) => {
            $(document).trigger('resize.unveil');
        });

    };

    // ENTRYPOINT
    App.start =  () => {
        $(function(){
            console.warn('On ready DOMContentLoaded');
            App.init();
        })
    }
    // EXTENDING APP
    const append = new AppExt(ctx); // passing private fn()
    Object.assign(App, append); // Extend App
    return App;

})(window, document);
