import base  from "./base_controller";
App.controller.home = class home_controller extends base {
    constructor() {
        super();
        // GLOBALES SI SE NECESITAN
    }

    index(){
        console.log("Home controller index action")
    }

}

