/**
 * Created by Estudioliver on 24/08/15.
 *
 * Ley de cookies 1 capa
 * Dependencies :jquery ,
 * injected : jquery.cookie
 *
 */

import * as $$ from 'jquery'

;(function(factory){if(typeof define==="function"&&define.amd){define(["jquery"],factory)}else if(typeof exports==="object"){module.exports=factory(require("jquery"))}else{factory(jQuery)}})(function($){var pluses=/\+/g;function encode(s){return config.raw?s:encodeURIComponent(s)}function decode(s){return config.raw?s:decodeURIComponent(s)}function stringifyCookieValue(value){return encode(config.json?JSON.stringify(value):String(value))}function parseCookieValue(s){if(s.indexOf('"')===0){s=s.slice(1,-1).replace(/\\"/g,'"').replace(/\\\\/g,"\\")}try{s=decodeURIComponent(s.replace(pluses," "));return config.json?JSON.parse(s):s}catch(e){}}function read(s,converter){var value=config.raw?s:parseCookieValue(s);return $.isFunction(converter)?converter(value):value}var config=$$.cookie=function(key,value,options){if(arguments.length>1&&!$.isFunction(value)){options=$.extend({},config.defaults,options);if(typeof options.expires==="number"){var days=options.expires,t=options.expires=new Date;t.setMilliseconds(t.getMilliseconds()+days*864e5)}return document.cookie=[encode(key),"=",stringifyCookieValue(value),options.expires?"; expires="+options.expires.toUTCString():"",options.path?"; path="+options.path:"",options.domain?"; domain="+options.domain:"",options.secure?"; secure":""].join("")}var result=key?undefined:{},cookies=document.cookie?document.cookie.split("; "):[],i=0,l=cookies.length;for(;i<l;i++){var parts=cookies[i].split("="),name=decode(parts.shift()),cookie=parts.join("=");if(key===name){result=read(cookie,value);break}if(!key&&(cookie=read(cookie))!==undefined){result[name]=cookie}}return result};config.defaults={};$.removeCookie=function(key,options){$$.cookie(key,"",$.extend({},options,{expires:-1}));return!$$.cookie(key)};$.cookieEnabled=function(){var testkey="testcookies";$$.cookie(testkey,1);var areEnabled=$$.cookie(testkey)==="1";$.removeCookie(testkey);return areEnabled}});

;(function($)
{
    // Plugin
    $.fn.cookielaw = function(options)
    {

        var val = [];
        var args = Array.prototype.slice.call(arguments, 1);

        if (typeof options === 'string')
        {
            this.each(function()
            {
                var instance = $.data(this, 'cookielaw');
                if (typeof instance !== 'undefined' && $.isFunction(instance[options]))
                {
                    var methodVal = instance[options].apply(instance, args);
                    if (methodVal !== undefined && methodVal !== instance) val.push(methodVal);
                }
                else return $.error('No such method "' + options + '" for CookieLaw');
            });
        }
        else
        {
            this.each(function()
            {
                $.data(this, 'cookielaw', {});
                $.data(this, 'cookielaw', CookieLaw(this, options));
            });
        }

        if (val.length === 0) return this;
        else if (val.length === 1) return val[0];
        else return val;

    };

    // Initialization
    function CookieLaw(el, options)
    {
        return new CookieLaw.prototype.init(el, options);
    }

    $.CookieLaw = CookieLaw;
    $.CookieLaw.NAME = 'cookielaw';
    $.CookieLaw.VERSION = '1.0';
    $.CookieLaw.opts = {

          info      : 'Aviso sobre cookies.',
          acceptname: 'Aceptar',
          kubemodal : false,
          opendelay : 1000,
          domain    : false

    };

    // Functionality
    CookieLaw.fn = $.CookieLaw.prototype = {

        // Initialization
        init: function(el, options)
        {

            this.$element = el !== false ? $(el) : false;

            this.isAccepted  =  parseInt($$.cookie('isAccepted'));

            this.loadOptions(options);

            if(!this.isAccepted)
            {
                setTimeout(
                    $.proxy(
                        function ()
                        {
                            this.build();
                        },
                        this),
                    this.opts.opendelay
                );

            }


        },
        loadOptions: function(options)
        {
            this.opts = $.extend(
                {},
                $.extend(true, {}, $.CookieLaw.opts),
                this.$element.data(),
                options
            );


            if(window.App && window.App.url)
            {
                this.opts.info=this.opts.info.replace('[URL]', app.url.base_url_lang);
            }
        },
        setCallback: function(type, e, data)
        {
            var events = $._data(this.$element[0], 'events');
            if (events && typeof events[type] != 'undefined')
            {
                var value = [];
                var len = events[type].length;
                for (var i = 0; i < len; i++)
                {
                    var namespace = events[type][i].namespace;
                    if (namespace == 'tools.' + $.CookieLaw.NAME || namespace == $.CookieLaw.NAME + '.tools')
                    {
                        var callback = events[type][i].handler;
                        value.push((typeof data == 'undefined') ? callback.call(this, e) : callback.call(this, e, data));
                    }
                }

                if (value.length == 1) return value[0];
                else return value;
            }

            return (typeof data == 'undefined') ? e : data;

        },
        build: function()
        {

            this.$element.attr('id','info_cookie_law')
                .append('<div>'+this.opts.info+' <button class="btn primary small" id="acceptCookies">'+this.opts.acceptname+'</button></div>').fadeIn('slow');

            this._installEvents();

        },
        _installEvents:function()
        {
            var options = { expires: (365 * 5), path: '/'};
             if(this.opts.domain) options.domain = this.opts.domain;

            this.$element.find('#acceptCookies').on(
                'click',
                $.proxy(function (e)
                {
                    $$.cookie('isAccepted', 1, options );
                    this.$element.fadeOut('slow');

                }, this)
            );

            if($.Modal && this.opts.kubemodal)
            {
                this.$element.find('a.cookie-modal').modal();
            }

        }

    };

    $(window).on('load.tools.cookielaw', function()
    {
        $("[data-tools='cookielaw']").cookielaw();
    });

    // constructor
    CookieLaw.prototype.init.prototype = CookieLaw.prototype;


})(jQuery);